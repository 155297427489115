.mypro__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.mypro__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.mypro__navbar-links_logo {
    margin-right: 2rem;
}

.mypro__navbar-links_logo img {
    width: 165px;
    height: 165px;
}

.mypro__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.mypro__navbar-sign {
    display: flex;
    justify-content: end;
    align-items: center;
}

.mypro__navbar-links_container p,
.mypro__navbar-sign p,
.mypro__navbar-menu_container p{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.mypro__navbar-sign button,
.mypro__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #2c2c24;
    background: #ebe704;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 24.59px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.mypro__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.mypro__navbar-menu svg {
    cursor: pointer;
}

.mypro__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem ;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0,0.2);
}

.mypro__navbar-menu_container p {
    margin: 1rem 0;
}

.mypro__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px){
    .mypro__navbar-links_container{
        display: none;
    }
    .mypro__navbar-menu{
        display: flex;
    }
    
}

@media screen and (max-width: 700px) {
    .mypro__navbar{
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .mypro__navbar{
        padding: 2rem;
    }
    .mypro__navbar-sign {
        display: none;
    }
    .mypro__navbar-menu_container {
        top: 20px;
    }
    .mypro__navbar-menu_container-links-sign{
        display: block;
    }
}