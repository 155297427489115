@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #302922 1.84%, #F49867 102.67%);
  --gradient-bar:  linear-gradient(90deg, rgba(238, 130, 238, 1) 18%, rgba(0, 209, 255, 1) 100%);
  
  --color-bg: #262825;
  --color-footer : #2a2e33;
  --color-blog: #042c54;
  --color-text: #3d3e3f;
  --color-subtext: #FF8A71;
}