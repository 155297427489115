*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body{
    background: var(--color-bg);
}

a {
color : unset;
text-decoration: none;
}

.gradient__bg{
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
}

.gradient__bg2
{
    /* ff 3.6+ */
    background: -moz-linear-gradient(90deg, rgb(184, 118, 118) 5%, rgba(255, 247, 138, 1) 40%, rgba(38, 40, 37, 1) 84%); 

    /* safari 5.1+,chrome 10+ */
    background: -webkit-linear-gradient(90deg,rgb(184, 118, 118) 5%, rgba(255, 247, 138, 1) 40%, rgba(38, 40, 37, 1) 84%);

    /* opera 11.10+ */
    background: -o-linear-gradient(90deg, rgb(184, 118, 118) 5%, rgba(255, 247, 138, 1) 40%, rgba(38, 40, 37, 1) 84%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#262825', endColorstr='#F5DFC6', GradientType=0 );

    /* ie 10+ */
    background: -ms-linear-gradient(90deg, rgb(184, 118, 118) 5%, rgba(255, 247, 138, 1) 40%, rgba(38, 40, 37, 1) 84%);

    /* global 94%+ browsers support */
    background: linear-gradient(90deg, rgb(184, 118, 118) 5%, rgb(204, 198, 117) 40%, rgba(38, 40, 37, 1) 84%);
}

.gradient__text{
    background: var(--gradient-text);
    background-clip: text ;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding{
    padding: 4rem 6rem;
}

.section__margin{
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-15 9:43:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @media screen and (max-width: 700px) {
    .section__padding{
        padding: 4rem;
    }

    .section__margin{
        margin: 4rem;
    }
  }

  @media screen and (max-width: 550px) {
    .section__padding{
        padding: 4rem 2rem;
    }

    .section__margin{
        margin: 4rem 2rem;
    }
  }
