.mypro__header {
    display: flex;
    }
    
    .mypro__header-content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 5rem;
    }
    
    .mypro__header-content h1{
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 62px;
        line-height: 75px;
        letter-spacing: -0.04em;
    
    }
    
    .mypro__header-content p{
        font-family: var(--font-family);
        font-weight: normal;
        font-style: normal;
        font-size: 20px;
        font-weight: 400;
        line-height: 27.32px;
        text-align: left;
        color: var(--color-text);
        margin-top: 1.5rem;
    }
    
    .mypro__header-content__input {
        width: 100%;
        margin: 2rem 0 1rem;
        display: flex;
    }
    
    .mypro__header-content__input input{
        flex: 2;
        width: 100%;
        min-height: 50px;
        background: var(--color-footer);
        border: 2px solid var(--color-footer);
        border-radius: 5px 0px 0px 5px;
        font-family: var(--font-family);
        font-weight: normal;
        font-style: normal;
        color: #3D6184;
        font-size: 20px;
        font-weight: 400;
        line-height: 27.32px;
        text-align: left;
    }
    
    .mypro__header-content__input button {
        flex: 0.6;
        width: 100%;
        min-height: 50px;
        background: #FF4820;
        border: none;
        border-radius: 0px 5px 5px 0px;
    
        color: #FFFFFF;
        font-family: var(--font-family);
        font-style: normal;
        font-size: 20px;
        font-weight: bold;
        line-height: 27.32px;
        cursor: pointer;
        outline: none;
        padding: 0 1rem;
    }
    
    .mypro__header-content__people{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        margin-top: 2rem;
    }
    
    .mypro__header-content__people img {
        width: 181.79px;
        height: 38px;   
    }
    
    .mypro__header-content__people p {
        margin: 0 0 0 1rem ;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: 500;
        line-height: 37.61px;
        text-align: center;
        color: #FFFFFF;
    
        width: 581.92px;
        height: 37.61px;
    }
    
    .mypro__header-image {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .mypro__header-image img{
        width: 100%;
        height: 100%;
    }
    
    @media screen and (max-width: 1050px) {
        .mypro__header {
            flex-direction: column;
        }
    
        .mypro__header-content {
            margin: 0 0 3rem;
        }
    }
    
    @media screen and (max-width: 650px) {
        .mypro__header h1 {
            font-size: 48px;
            line-height: 60px;
        }
    
        .mypro__header-content p {
            font-size: 16px;
            line-height: 24px;
        }
        
        .mypro__header-content__people {
            flex-direction: column;
        }
    
        .mypro__header-content__people p {
            margin: 0;
        }
    
        .mypro__header-content__input input,
        .mypro__header-content__input button {
            font-size: 16px;
            line-height: 24px;
        }
    }
    
    @media screen and (max-width: 490px) {
        .mypro__header-content h1 {
            font-size: 36px;
            line-height: 48px;
        }
    
        .mypro__header-content p {
            font-size: 14px;
            line-height: 24px;
        }
    
        .mypro__header-content__input input,
        .mypro__header-content__input button {
            font-size: 12px;
            line-height: 16px;
        }
    }